:root{
    --background:#1c1c1c;
    --background0:#292929;
    --background1:#000;
    --background2:#424242;
    --background3:#5a5a5a;
    --black1:#242424;
    --black2:#383838;
    --text:#fff;
    --textblur:#c1c1c1;
    --border:#cccccc7c;
    --shadow:#ccc;
    --logocolor:#147fc2;
    --logocolor20:#147fc22a;
    --logocolor40:#147fc271;
    --logocolor70:#147fc2ab;
    --logocolor85:#147fc2d5;
    --logocolorinvert:#eb803d;
    --coverbackground: #147fc215;
    --coverblack: #00000041;
    --black10:rgba(0, 0, 0, 0.10);
    --black20:rgba(0, 0, 0, 0.20);
    --black30:rgba(0, 0, 0, 0.30);
    --black40:rgba(0, 0, 0, 0.40);
    --black50:rgba(0, 0, 0, 0.50);
    --black60:rgba(0, 0, 0, 0.60);
    --black70:rgba(0, 0, 0, 0.70);
    --black80:rgba(0, 0, 0, 0.80);
    --black90:rgba(0, 0, 0, 0.90);
}
html, body{float:left;width:100%;height:100vh;background-color:var(--background)!important;color:var(--text)!important;}
a{color:var(--text)!important;text-decoration: none!important;cursor:pointer;}
ul{float:left;width:100%;height:auto;list-style-type: none;margin:0;padding:0!important;}
li{float:left;width:100%;margin:0;}
.fl{float:left;}.fw{width:100%;}.taC{text-align: center;}
.wmt-1{margin-top:1%;}.wmt-2{margin-top:2%;}.wmt-3{margin-top:3%;}.wmt-5{margin-top:5%;}
.wmb-1{margin-bottom:1%;}.wmb-2{margin-bottom:2%;}.wmb-3{margin-bottom:3%;}.wmb-5{margin-bottom:5%;}
.wmt-4{margin-top:4px;}.wmt-6{margin-top:6px;}.wmt-10{margin-top:10px;}.wmt-15{margin-top:15px;}.wmt-20{margin-top:20px;}.wmt-30{margin-top:30px;}.wmt-50{margin-top:50px;}
.wmb-10{margin-bottom:10px;}.wmb-20{margin-bottom:20px;}.wmb-30{margin-bottom:30px;}.wmb-50{margin-bottom:50px;}
.hidden{display:none;}
.leftAreaC{float:left;width:22%!important;}
.fullAreaC{float:left;width:78%!important;}
.midAreaC{float:left;width:65%!important;border-left:1px solid var(--border);border-right: 0.3px solid var(--border);padding:0!important;min-height: 100vh;}
.rightAreaC{float:left;width:33%!important;margin-left:2%;}
.postArea{float:left;Width:100%;}
.loginArea{float:left;Width:100%;}
.loginAreaTop{float:left;width:100%;height:80px;box-shadow:0 0 5px 3px var(--background2);position:fixed;top:0;left:0;padding:10px 10%;background:linear-gradient(45deg, var(--textblur) 0%, var(--background3) 20%, var(--background2) 40%, var(--background) 70%, var(--background1) 100%);z-index:999;}
.latLogo{float:left;width:300px;}
.latLogo img{float:left;height:70px;}
.latLogo a{float:left;margin:0 0 0 20px;font-size:20px;font-weight: 400;line-height: 60px;}
.latButton{float:right;width:240px;}
.latButton a{float:left;border:2px solid var(--logocolor);margin:15px 0;border-radius:30px;font-size:18px;position:relative;}
.latButton a:last-child{background-color:var(--logocolor);margin-left:30px;}
.latButton a span{padding:7px 25px;}
.latButton a span:last-child{padding:4.5px 25px;}
.loginForm{float:left;width:380px;background-color:var(--background);position:absolute;top:45px;left:-70px;border-radius:30px;box-shadow: 0 0 5px 3px var(--background2);padding:20px 25px 15px;display:none;}
.lfHeader{float:left;width:100%;height:80px;padding:10px 0;display:flex;justify-content: center;}
.lfHeader img{float:left;height:80px;margin-right:30px;}
.lfHeader h3{float:left;font-size:18px;line-height: 100px;text-transform: uppercase;font-weight: 500;}
.lfForm{float:left;width:100%;margin:20px 0 0;}
.lfForm input{float:left;width:100%;margin:5px 0 7px;font-size:14px;padding:5px 10px;border-radius:5px;}
.lfForm a{float:left;width:100%;margin:10px 0!important;text-align: center;border-radius:5px!important;padding:3px 10px!important;text-transform: uppercase;font-size:14px;font-weight: 600;background-color:var(--logocolor);}
.lfFormCheck{float:left;width:100%;border-bottom:1px solid var(--background3);padding:0 0 10px 0;margin:5px 0 10px;}
.lfFormCheck input{float:left;width:14px;height:15px;margin:2px 5px 0 0;}
.lfFormCheck span{float:left;font-size:12px;letter-spacing: 0px;}
.lfFormCheck a{float:right;font-size:12px;color:var(--logocolor)!important;border:none!important;background-color:transparent!important;width:auto;letter-spacing: -0.5px;margin:0!important;padding:0!important;}
.lfFormOther{float:left;width:100%;}
.lfSignUp{float:left;width:100%;font-size:16px;cursor:pointer;}
.lfSignUp span{font-size:16px;color:var(--logocolor);text-transform: uppercase;font-weight: 600;letter-spacing: -0.5px;}
.lfFormOther h6{float:left;width:100%;text-align: center;font-size:15px;margin:20px 0 10px;}
.lfFormOther a{float:left;width:46%;margin-right:8%!important;border-radius:30px!important;background-color:var(--background2);border:1px solid var(--background3);display:flex;justify-content: center;}
.lfFormOther a img{float:left;width:20px;height:20px;margin:1px 10px 1px 0;}
.lfFormOther a:last-child{margin-right: 0!important;background-color:var(--background2);border:1px solid var(--background3);}
#memberOk{float:left;width:100%;color:greenyellow;font-weight: 600;text-align: center;display:none;}

.LoginAreaBanner{float:left;width:100%;height:350px;position:absolute;left:0;top:80px;}
.LoginAreaBanner img{float:left;width:100%;height:100%;object-fit: cover;}
.LoginAreaBanner h1{float:left;width:100%;height:100%;position:absolute;background-color:var(--coverblack);display:flex;align-items: center;justify-content: center;}
.loginAreaCreators{float:left;width:100%;margin-top:450px;padding:20px 0;}
.loginAreaCreators h2{float:left;width:100%;font-size:42px;margin:0;letter-spacing: 1px;}


.leftArea{float:left;padding:5% 10% 5% 0;}
.laUser{float:left;width:100%;margin:10px 0;border-bottom:1px solid var(--shadow);}
.laUserInfo{float:left;width:100%;margin:10px 0 7px;padding:10px 10px;border-radius:10px;transition:1s;}
.laUserInfo:hover{background-color:var(--logocolor);}
.laUserInfo img{float:left;width:60px;height:60px;object-fit:cover;border-radius:50%;border:1px solid #454545;background-color:var(--background);padding:5px;transition:1s;}
.laUserInfo img:hover{box-shadow:0 0 10px 5px #454545;}
.laUserInfo p, .laUser a{max-width: 150px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.laUserInfo p{float:left;width:calc(100% - 80px);margin:5px 0 0 20px;font-size:20px;line-height: 20px;margin-bottom:5px;font-weight: 600;}
.laUserInfo a{float:left;width:calc(100% - 80px);margin:0 0 0 20px;font-size:18px;color:var(--textblur)!important;}
.laUserData{float:left;width:44%;color:var(--text);transition:1.2s;margin:0 3% 15px 3%;padding:5px 0;}
.laUserData:hover{background-color:var(--logocolor);border-radius:10px;}
.laUserData h6{float:left;width:100%;text-align: center;font-weight: 600;font-size:20px;margin:0;}
.laUserData p{float:left;Width:100%;text-align:center;color:var(--textblur);margin:5px 0 0 0;}
.laLogo{float:left;width:100%;margin:2% calc(100% - 120px) 5% 0;}
.laLogo img{float:left;width:120px;}
.laMenu{float:left;width:100%;}
.laMenu li{float:left;width:100%;margin:5px 0 0px;padding:10px;border-radius:30px;transition:0.6s;}
.laMenu li img{float:left;width:35px;margin-right:10px;filter:invert(1);padding:5px;border-radius:5px;transition:1.2s;}
.laMenu li p{float:left;width:calc(100% - 50px);font-weight: 400;font-size:18px;line-height: 35px;margin:0;}
.laMenu li:hover{background-color:var(--logocolor40);}
.laMenu li:hover img{background-color:var(--logocolorinvert);border-radius:18px!important;}
.laMenuActive{background-color: var(--logocolorinvert);border-radius:18px!important;}
#lacUsers{float:left;width:100%;padding:15px;}
.lacUser{float:left;Width:23%;margin:1.5% 1% 1.5% 1%;position:relative;height:420px;border-radius:20px;}
.lacUser img{position: absolute;width:100%;height:100%;object-fit: cover;top:0;left:0;border-radius:20px;}
.lacUserDatas{float:left;width:100%;height:100%;position:absolute;background-color:var(--coverblack);padding:10px;border-radius:20px;}
.lacUserDatas p{float:left;width:100%;max-width: 90%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;position:relative;top:300px;margin:0;}
.lacUserLink{float:left;width:100%;max-width: 90%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;position:relative;top:300px;margin:0;}
.lacUserData{float:left;width:100%;position:relative;top:300px;left:0;}
.lacUserData a{float:left;width:auto;margin:5px 10px 5px 2px;}
.lacUserData a img{float:left;width:22px;height:22px;object-fit: cover;filter:invert(1);position:unset;margin-right: 3px;border-radius: 0;}
.lacSm{float:left;width:100%;margin:30px 0;}
.lacSm a{float:left;width:500px;font-size:36px;background-color: var(--logocolor);padding:15px 30px;
margin:0 calc(50% - 250px);text-align: center;border-radius:50px;}

.loginAreaFooter{float:left;width:100%;padding:50px 0 0;}
.lafLogo{float:left;width:16%;margin-right:4;}
.lafLogo img{float:left;width:150px;}
.lafArea{float:left;width:18%;margin-right:2%;}
.lafArea h5{float:left;width:100%;margin:0 0 20px;}
.lafArea a{float:left;width:100%;margin:5px 0;}
.lafArea a img{float:left;width:18px;height:18px;margin:4px 0 0;object-fit:contain;margin-right:10px;}
.lafCopy{float:left;Width:100%;text-align: center;margin:50px 0 10px;}

.midArea{float:left;width:100%;min-height:100vh;overflow: scroll;}
.midArea::-webkit-scrollbar{display:none;}
.midAreaTop{float:left;width:100%;padding:20px;border-bottom:1px solid var(--border);}
.midAreaTop h1{float:left;width:80%;font-size:22px;}
.midAreaTop a{float:right;width:20%;}
.midAreaTop a img{float:right;height:30px;}
.midAreaFilter{float:left;width:100%;display:flex;align-items:flex-start;padding:10px;}
.midAreaFilter a{float:left;padding:5px 15px;background-color:var(--background3);color:var(--text);margin:10px;border-radius:20px;transition:0.7s;}
.midAreaFilter a:hover{background-color:var(--logocolor40);}
.midAreaFilterActive{background-color: var(--logocolor)!important;}
.midAreaContents{float:left;width:100%;padding:20px 0;}
.midAreaContent{float:left;width:100%;margin:0 0;padding:2% 0 1%;border-bottom:1px solid var(--border);}
.macUserInfo{float:left;width:100%;padding:3% 5%;}
.macUserInfo img{float:left;width:40px;height:40px;border-radius:50%;object-fit:cover;margin-right:10px;background-color:var(--text);padding:2px;}
.macUserInfo p, .macUserInfo a{max-width: calc(100% - 100px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.macUserInfo p{float:left;width:calc(100% - 80px);margin:0 0 3px 10px;font-size:16px;line-height: 20px;font-weight: 600;line-height: 16px;}
.macUserInfo a{float:left;width:calc(100% - 80px);margin:0 0 0 10px;font-size:15px;color:var(--textblur)!important;}
.macContentDesc{float:left;width:100%;padding:10px 5% 30px;}
.macContentImage{float:left;width:100%;height:auto;height:400px;background-color:var(--text);position:relative;}
.macContentVideo{float:left;width:100%;height:auto;height:400px;background-color:transparent;position:relative;}
.macContentVideo img{float:left;width:100%;height:400px;object-fit: cover;position:absolute;left:0;top:0;}
.macContentImage img{float:left;width:100%;height:400px;object-fit: cover;position:absolute;left:0;top:0;}
.macContentSubImages{float:left;width:100%;height:auto;margin-top:10px;display: flex;align-items: center;justify-content: center;}
.macContentSubImage{float:left;width:10%;margin-right: 0.5%;;}
.macContentSubImages img{float:left;width:100%;height:100px;object-fit: contain;object-position: top;}
.postDetailImg{object-fit: contain!important;object-position:center;background-color: var(--background);}
.unLockPost{float:left;width:100%;position:absolute;background-color:var(--coverbackground);width:100%;height:400px;left:0;top:0;}
.unLockPost a{float:left;width:80%;height:auto;padding:10px;text-align: center;position:absolute;left:10%;bottom:20px;color:white;background:radial-gradient(circle, var(--logocolor) 40%, var(--logocolor85) 80%,var(--logocolor70) 100%);border-radius:20px;}
.macContentImages{float:left;width:100%;height:200px;padding:5px 1px;}
.macContentImages img{float:left;width:32.6%;margin-right:1%;height:150px;object-fit: cover;}
.macContentImages img:last-child{margin-right:0;}
.macContentFunctions{float:left;width:100%;padding:10px;}
.macContentFunction{float:left;width:auto;height:25px;margin:0 20px 0 0;}
.macContentFunction img{float:left;width:22px;height:22px;object-fit: contain;background-repeat: no-repeat;}
.macContentFunction p{float:left;width:auto;margin:0 5px 0 7px;line-height: 22px;}
.macContentBookmark{float:right;width:auto;height:25px;margin:0 20px 0 0;}
.macContentBookmark img{float:left;width:22px;height:22px;object-fit: contain;background-repeat: no-repeat;}
.macContentBookmark p{float:left;width:auto;margin:0 5px 0 7px;line-height: 22px;}
.macContentTipArea{float:left;width:auto;margin:0 10px 0 0;display:none;}
.macContentTipArea select{float:left;width:70px;background-color:var(--background);color:var(--text);border-radius:5px;padding:1px 10px;}
.macContentTipArea a{float:left;padding:1.5px 10px;background-color:var(--logocolor);color:var(--text)!important;border-radius:5px;margin:0 0 0 10px;font-weight: 600;}
.macContentComments{float:left;width:100%;height:auto;padding:10px;display:none;}
.macContentComment{float:left;width:100%;margin:10px 0;background-color: var(--background2);padding:10px;border-radius:10px;}
.macContentComment img{float:left;width:40px;height:40px;object-fit:cover;border-radius:50%;margin-right: 10px;}
.macContentComment p{float:left;width:calc(100% - 50px);height:auto;margin:0 0 1px;font-size:14px;color:var(--text);line-height: 20px;}
.macContentComment a{float:left;width:calc(100% - 50px);height:auto;margin:0 0 5px;font-size:13px;color:var(--shadow)!important;line-height: 16px;}
.macContentCommentData{float:left;width:100%;}
.macContentAddComment{float:left;width:100%;}
.macContentEmojiArea{float:left;width:100%;margin:3px 0;display:none;}
.macContentEmojiArea a{float:left;width:auto;background-color:var(--background);border-radius:10px;}
.macContentTextArea{float:left;width:100%;position:relative;height:100px;}
.macContentTextArea textarea{float:left;width:100%;height:100px;border:1px solid var(--shadow);background-color:var(--background);color:var(--text);border-radius:10px;padding:10px 40px 10px 10px;}
.macContentTextArea .send{position: absolute;right:15px;bottom:10px;}
.macContentTextArea .send img{height: 30px;width: 30px;object-fit: cover;}
.macContentTextArea .emoji{position: absolute;right:20px;top:10px;}
.macContentTextArea .emoji img{height: 20px;width: 20px;object-fit: cover;}

#upUcMedias{float:left;width:100%;padding:5px 10px;display:none;}
.mamTab{float:left;width:100%;margin:5px 0;}
.mamTab a{float:left;background-color: var(--background2);text-align: center;padding:5px 20px;margin:5px 10px;border-radius:50px;}
.mamTabActive{background-color: var(--logocolor)!important;}
.mediaAreaMedias{float:left;width:100%;height:auto;}
.mediaAreaMedia{float:left;width:25%;height:140px;padding:2px;}
.mediaAreaMedia1{float:left;width:100%;height:100%;}
.mediaAreaMedia1 video{float:left;width:100%;height:100%;object-fit: cover;border-radius:3px;}
.mediaAreaMedia1 img{float:left;width:100%;height:136px;object-fit: cover;border-radius:3px;}

.modalMedia{float:left;position:fixed;left:0;top:0;width:100%;height:100vh;display:none;align-items: center;justify-content: center;background-color:var(--black70);z-index:99999;}
.mmDatas{float:left;max-width:80%;width:500px;max-height:70vh;position:relative;z-index:9999;}
.mmDatas a{float:left;width:auto;height:auto;padding:10px;background-color:var(--text);border-radius:50%;border:1px solid var(--shadow);box-shadow: 0 0 5px 3px var(--shadow);}
.mmDatas a img{float:left;width:30px;height:30px;object-fit: cover;}
.mmDatasLeft{position:absolute;left:-25px;top:calc(50% - 25px);padding:10px 13px 10px 7px!important;z-index:9999;}
.mmDatasRight{position:absolute;right:-25px;top:calc(50% - 25px);transform: rotate(180deg);padding:10px 13px 10px 7px!important;z-index:9999;}
.mmDatasImage{float:left;width:100%;height:100%;display: flex;align-items: center;justify-content: center;}
.mmDatasImage img{float:left;max-width: 100%;max-height:100%;object-fit: cover;}
.mmDatasVideo{float:left;width:100%;height:100%;display: flex;align-items: center;justify-content: center;}
.mmDatasVideo video{float:left;max-width: 100%;max-height:100%;object-fit: cover;}
.mmClose{float:left;position: absolute;left:0;top:0;font-size:18px;font-weight: 600;z-index:9998;width:100%;height:100%;backdrop-filter: blur(10px);}
.modalTip{float:left;position:fixed;left:0;top:0;width:100%;height:100vh;display:none;align-items: center;justify-content: center;background-color:var(--black70);z-index:99999;}
#mtAreaUser{display:none;}
#mtAreaPost{display:none;}
.mtArea{float:left;width:450px;border-radius: 10px;z-index:9999;padding:20px;background:linear-gradient(27deg, var(--black2) 30%, var(--black1) 60%, var(--background) 100%);position:relative;box-shadow:0 0 5px 1px var(--background2)}
.mtArea h5{float:left;width:100%;font-size:20px;margin:0 0 10px 0;text-align: center;}
.mtArea span{float:right;position:absolute;right:10px;top:0;font-size:22px;cursor: pointer;}
.mtUserArea{float:left;width:100%;height:auto;}
.mtUserArea img{float:left;width:40px;height:40px;margin-right:10px;object-fit: cover;border-radius: 50%;}
.mtUserArea p{float:left;width:calc(100% - 50px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;font-weight: 500;}
.mtUserArea a{float:left;width:calc(100% - 50px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;font-size:14px;}
.mtAmountArea{float:left;width:100%;position:relative;margin:20px 0 10px;}
.mtAmountArea img{float:left;width:30px;height:30px;background-color:var(--background1);position:absolute;left:1px;top:1px;padding:6px;border-radius:5px 0 0 5px;}
.mtAmountArea input{float:left;width:100%;background-color:var(--background);border:1px solid var(--border);border-radius:5px;line-height: 32px;padding:0 0 0 40px;color:var(--text);}
.mtAmountArea p{float:left;margin:0;position: absolute;top:-10px;left:10px;font-size:12px;background-color: var(--background2);padding:0 5px;border-radius:5px;z-index:9998;}
.mtDescArea{float:left;width:100%;position:relative;margin:20px 0;}
.mtDescArea textarea{float:left;width:100%;background-color:var(--background);border:1px solid var(--border);border-radius:5px;line-height: 32px;color:var(--text);padding:10px;}
.mtDescArea p{float:left;margin:0;position: absolute;top:-10px;left:10px;font-size:14px;background-color: var(--background2);padding:0 5px;border-radius:5px;}
.mtButtonArea{float:left;width:100%;padding:10px;}
.mtButtonArea a{float:left;width:47%;margin:0 3% 0 0;border:1px solid var(--logocolor);color:var(--logocolor)!important;text-align: center;padding:10px 0;border-radius:30px;font-weight: 600;}
.mtButtonArea a:last-child{background-color: var(--logocolor);color:var(--text)!important;margin:0 0 0 3%;}

.rightArea{float:left;width:100%;padding:5% 0;}
.rightArea img{float:left;max-width: 100%;}
.raSearch{float:left;width:100%;margin:2% 0 30px;position:relative;}
.raSearch input{float:left;width:100%;background-color:var(--background2);color:var(--text);border-radius:20px;padding:10px;border:transparent;
background-image: url('../icons/search_w.webp');background-size:16px;background-position:25px 11px;background-repeat: no-repeat;padding-left:50px;line-height: 18px;}
.raSearch input::placeholder{color:#9c9c9c;font-weight: 500;}
.raSuggestions{float:left;width:100%;background-color:var(--background2);padding:5%;border-radius:20px;}
.rasTitle{float:left;width:100%;margin:2% 0 5%;}
.rasTitle h4{float:left;Width:60%;color:var(--textblur);}
.rasUsers{float:left;width:100%;}
.rasUser{float:left;position:relative;width:100%;margin:10px 0;background-size:cover!important;background-position: center!important;background-repeat: no-repeat!important;padding:20px 10px;border-radius:15px;}
.rasUserCover{float:left;width:100%;height:100%;background-color:#00000048;z-index:998;position:absolute;top:0;left:0;border-radius:15px;}
.rasUserData{float:left;width:100%;z-index:999;position:relative;}
.rasUserData img{float:left;width:60px;height:60px;object-fit: cover;border-radius:50%;margin-right:20px;}
.rasUserData p, .rasUserData a{max-width: 150px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;}
.rasUserData p{float:left;width:calc(100% - 80px);font-size:16px;font-weight: 500;margin:5px 0 0;}
.rasUserData span{float:left;width:calc(100% - 80px);color:var(--textblur)!important;}
.rasStories{float:left;width:100%;background-color:var(--background2);padding:5%;border-radius:20px;margin:10px 0;}
.rasStory{float:left;width:90%!important;height:160px;position:relative;width:100%;margin:10px 5%;background-size:cover!important;background-position: center!important;background-repeat: no-repeat!important;border:2px solid var(--logocolor);border-radius:10px;padding:0!important;}
.rasStory img{position:relative;top:5px;left:5px;width:35px;height:35px;border-radius:50%;border:2px solid var(--logocolor)}
.rasPolicies{float:left;width:100%;background-color:var(--background2);padding:5%;border-radius:20px;margin:10px 0;}
.rasPolicy{float:left;width:100%;height:auto;padding:10px;}
.rasPolicies ul>li{list-style: circle;float:left;width:auto;margin-left:30px;}
.rasPolicies ul>li::marker{margin:0 2px;}

.slick-prev:before, .slick-next:before{font-size:26px!important;}

.userProfile{float:left;width:100%;}
.upUserTop{float:left;width:100%;background-size:cover!important;background-position:center!important;background-repeat:no-repeat;height:230px;padding:10px;position:relative;}
.upUserTopCover{float:left;width:100%;position:absolute;height:55px;left:0;top:0;background-color: var(--coverblack);box-shadow: 0 0 10px 10px #00000033;}
.upUserTop h3{float:left;width:calc(100% - 60px);position:relative;z-index:999;}
.leftArrow{float:left;padding:7px 10px 10px;border-radius:50%;transition:0.6s;margin-right:10px;position:relative;z-index:999;}
.leftArrow img{width:20px;height:20px;object-fit: cover;filter:invert(1);}
.leftArrow:hover{background-color:var(--logocolor);}
.copyIcon{float:left;padding:7px 12px 10px;border-radius:50%;transition:0.6s;margin-right:10px;position: absolute;right:20px;bottom:-60px;border:2px solid var(--logocolor);}
.copyIcon img{width:18px;height:18px;object-fit: cover;}
.copyIcon:hover{background-color:var(--background2);}
.messageIcon{float:left;padding:7px 12px 10px;border-radius:50%;transition:0.6s;margin-right:10px;position: absolute;right:80px;bottom:-60px;border:2px solid var(--text);}
.messageIcon img{width:18px;height:18px;object-fit: cover;filter:invert(1)}
.messageIcon:hover{background-color:var(--background2);}
.settingIcon{float:left;padding:7px 11px 10px;border-radius:50%;transition:0.6s;margin-right:10px;position: absolute;right:80px;bottom:-60px;border:2px solid var(--logocolor);}
.settingIcon img{width:20px;height:20px;object-fit: cover;}
.settingIcon:hover{background-color:var(--background2);}
.upUserPP{float:left;position:absolute;left:20px;bottom:-50px;}
.upUserPP{float:left;width:90px;height:90px;border-radius: 50%;object-fit: cover;}
.upUserMid{float:left;width:100%;margin-top:55px;padding:10px 20px;}
.upUserMid h2{float:left;width:auto;font-size:28px;margin-right:10px;}
.upUserMidApprove{float:left;width:25px;height:25px;object-fit: cover;margin:5px 0 0;}
.upUserMid a{float:left;width:100%;color:var(--textblur);}
.upUserFllw{float:left;width:100%;padding:10px;}
.upUserFllw a{float:left;width: auto;margin:0 10px 0 15px;font-size:16px;}
.upUserDescription{float:left;width:100%;margin:10px 0;padding:0 0 0 20px;}
.upUserF{float:left;width:100%;padding:10px 20px;}
.upUserF a{float:left;width:100%;color:var(--logocolor);border:2px solid var(--logocolor);padding:10px;text-align: center;border-radius:25px;font-size:16px;font-weight: 600;transition:0.5s;}
.upUserF a:hover{box-shadow:0 0 5px 1px var(--border);}
.upUserS{float:left;width:calc(100% - 40px);margin:10px 20px;background:linear-gradient(300deg, var(--logocolor), var(--logocolor40));border:2px solid var(--logocolor);
border-radius:25px;padding:0 10px;transition:0.3s;cursor:pointer;}
.upUserS:hover{background:linear-gradient(60deg, var(--logocolor), var(--logocolor40));box-shadow:0 0 5px 1px var(--border);}
.upUserS a{float:left;width:auto;padding:10px;font-size:16px;font-weight: 600;text-align: left;}
.upUserSPrice{float:right!important;width:auto;text-align: right;}

.upUserContent{float:left;width:100%;margin:10px 0;}
.upUcTop{float:left;width:100%;}
.upUcTop a{float:left;width:50%;padding:10px 0;text-align: center;}
.upUcTopNormal{border-bottom:1px solid var(--border)!important;color:var(--border)!important;font-weight: 500!important;}
.upUcTopActive{border-bottom:2px solid var(--logocolor)!important;color:var(--logocolor)!important;font-weight: 600!important;}

.ajs-message{border-radius:20px;font-size:16px;font-weight: 500;}

.exploreArea{float:left;width:100%;min-height:100vh;border-left:1px solid var(--border);}
.eaHeader{float:left;width:100%;padding:10px 10px 0;border-bottom:1px solid var(--border)}
.eaHeader h3{font-size:22px;margin:5px 0 0;}
.eahFilter{float:left;width:100%;}
.eahFilter a{float:left;width:auto;padding:5px 10px;}
.eahFilter a img{float:left;width:20px;height:20px;object-fit: cover;filter:invert(1);margin:0 10px 0 0;}
.eahFilterActive{border-bottom:2px solid var(--logocolor)}
.eaBody{float:left;width:100%;}
#eaUsers{float:left;width:100%;padding:15px;}
#eaPosts{float:left;width:100%;padding:15px ;display:none;}
#eaVideos{float:left;width:100%;padding:15px ;display:none;}
.eaUser{float:left;Width:18%;margin:1.5% 1% 1.5% 1%;position:relative;height:250px;border-radius:20px;}
.eaUser img{position: absolute;width:100%;height:250px;object-fit: cover;top:0;left:0;border-radius:20px;}
.eaUserDatas{float:left;width:100%;height:250px;position:absolute;background-color:var(--coverblack);padding:10px;border-radius:20px;}
.eaUserDatas p{float:left;width:100%;max-width: 90%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;position:relative;top:155px;margin:0;}
.eaUserLink{float:left;width:100%;max-width: 90%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;position:relative;top:155px;margin:0;}
.eaUserData{float:left;width:100%;position:relative;top:155px;left:0;}
.eaUserData a{float:left;width:auto;margin:5px 10px 5px 2px;}
.eaUserData a img{float:left;width:22px;height:22px;object-fit: cover;filter:invert(1);position:unset;margin-right: 3px;border-radius: 0;}

.eaPost{float:left;Width:31%;margin:1.5% 1% 1.5% 1%;position:relative;height:420px;border-radius:20px;}
.eaPost img{position: absolute;width:100%;height:420px;object-fit: cover;top:0;left:0;border-radius:20px;}
.eaPost video{position: absolute;width:100%;height:335px;margin-top:55px;object-fit: cover;top:0;left:0;border-radius:0;}
.eaPostDatas{float:left;width:100%;height:420px;position:absolute;background-color:var(--coverblack);padding:10px;border-radius:20px;}
.eaPostDatas p{float:left;width:100%;max-width: 90%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;position:relative;top:155px;margin:0;}
.eaPostUser{float:left;position:absolute;top:0;left:0;background-color: var(--background1);color:white;width: 100%;padding:7px 10px;z-index:9999;cursor:pointer;}
.eaPostUser img{float:left;width: 45px;height: 45px;border-radius:50%;position: unset;margin-right: 15px;}
.eaPostUser p{float:left;width:100%;max-width: calc(90% - 80px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;position: unset;font-weight: 600;}
.eaPostUser a{float:left;width:100%;max-width: calc(90% - 80px);;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;position: unset;font-size:13px;}
.eaPostUser span{float:right;width:10%;}
.eaPostUser span img{float:left;;width: 10px;height:25px;object-fit:cover;filter:invert(1);border-radius:0;position:absolute;top:15px;left:calc(90% + 10px);}

.eaPostFunctions{float:left;width:100%;position: absolute;left:0;bottom:0;background-color:var(--background1);padding:20px 10px;z-index:9999;}
.eaPostFunction{float:left;width:45px;height:25px;margin:0 10px 0 0;position:relative;}
.eaPostFunction img{float:left;width:22px;height:22px;object-fit: contain;background-repeat: no-repeat;position:unset;border-radius: 0;}
.eaPostFunction p{float:left;width:auto;margin:0 5px 0 7px;line-height: 22px;position:unset;}
.eaPostTip{width:100px;}
.eaPostBook{float:right;width:25px;margin-right: 0;}
.eaPostTipArea{float:left;width:auto;margin:0 10px 0 0;display:none;}
.eaPostTipArea select{float:left;width:70px;background-color:var(--background);color:var(--text);border-radius:5px;padding:1px 10px;}
.eaPostTipArea a{float:left;padding:1.5px 10px;background-color:var(--logocolor);color:var(--text)!important;border-radius:5px;margin:0 0 0 10px;font-weight: 600;}


.ntfHeader{float:left;width:100%;padding:10px 10px 0;}
.ntfHeader h3{font-size:22px;margin:5px 0 0;}
.ntfFilter{float:left;width:100%;margin:20px 0;}
.ntfFilter a{float:left;width:auto;padding:5px 10px;background-color:var(--background3);padding:7px 15px;border-radius:30px;margin:0 12px 0 0;font-weight: 500;font-size:18px;}
.ntfFilter a img{float:left;width:20px;height:20px;object-fit: cover;filter:invert(1);margin:0 10px 0 0;}
.ntfFilterActive{background-color: var(--logocolor)!important;}
.notify{float:left;width:calc(100% - 30px);margin:0 15px 10px 15px;border-radius:10px;background-color: var(--background2);border-radius: 20px;padding:10px;}
.notify img{float:left;width:45px;height:45px;object-fit: cover;margin:10px 10px 0 0;border-radius:50%;background-color: white;}
.notifyDatas{float:left;width:calc(100% - 70px);margin:5px 0 5px;}
.notifyDatas a{font-size:16px;font-weight: 500;width:100%;line-height: 16px;}
.notifyDatas p{float:left;width:100%;margin:0;font-size:15px;line-height: 15px;}
.notifyDatas p a{font-weight: 400;color:var(--logocolor)!important;width:auto;margin:0;line-height: 15px!important;}
.notifyDatas span{float:left;width:100%;margin:0;color:var(--shadow);font-size: 13px;line-height: 15px;margin:3px 0 0;}

.allMessages{float:left;width:35%;border-left:1px solid var(--border);border-right:1px solid var(--border);height:100vh;overflow-y:scroll;overflow-x: hidden;}
.allMessages::-webkit-scrollbar{display:none;}
.amHeader{float:left;width:100%;border-bottom:1px solid var(--border);padding:10px;}
.amHeader h3{font-size:22px;margin:5px 0 0;}
.amFilter{float:left;width:100%;padding:10px;border-bottom:1px solid var(--border);}
.amFilter p{float:left;width:calc(100% - 30px);margin:0;line-height: 30px;font-size:16px;font-weight: 500;}
.amFilter img{float:left;Width:30px;height:30px;object-fit: cover;filter:invert(1)}
.amSelect{float:left;width:100%;padding:15px 10px;}
.amSelect a{float:left;padding:5px 12px;border-radius: 30px;background-color:var(--background3);margin:0 10px 5px 0;}
.amSelectActive{background-color:var(--logocolor)!important;}
.amMessage{float:left;width:90%;height:auto;background-color:var(--background2);border-radius:10px;padding:10px;position:relative;overflow: hidden;margin:10px 5%;cursor:pointer;}
.amMessagePic{float:left;width:50px;height:50px;object-fit: cover;border-radius:50%;margin-right:10px;}
.amMessageUser{float:left;width:calc(100% - 70px);}
.amMessageUser p{float:left;width:auto;margin-right:5px;margin:0;font-weight: 600;margin-right:5px;font-size:15px;}
.amMessageUser a{float:left;width:auto;margin-right:5px;color:var(--shadow)!important;margin:0;font-size:15px;}
.amMessageLast{float:left;width:calc(100% - 70px);}
.amMessageLast p{float:left;max-width:80%!important;margin:0;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.amMessageLast span{float:left;max-width:20px;font-size:14px!important;color:var(--shadow);margin:2px 0 3px 5px;}
.amMessageTrash{position: absolute;right:-60px;top:0;height:100%;background: var(--background3);padding:10px 12px;display:flex;align-items: center;justify-content: center;transition: 0.8s;cursor:pointer;}
.amMessageTrash img{float:left;width:30px;height:30px;object-fit: cover;}

.nowMessages{float:left;width:65%;border-right:1px solid var(--border);height: 100vh;}
.nmHeader{float:left;width:100%;border-bottom:1px solid var(--border);padding:10px 10px 11px;height:62px;}
.nmHeader img{float:left;width:40px;height:40px;object-fit: cover;border-radius:50%;margin-right:10px;}
.nmHeader h3{font-size:22px;margin:5px 0 0;}
.nmMessage{float:left;width:100%;height:75vh;border-bottom:1px solid var(--border);overflow-y: scroll;overflow-x: hidden;}
.nmMessage::-webkit-scrollbar{display:none;}
.nmMessageFree{float:left;width:100%;height:75vh;display:flex;align-items: center;justify-content: center;flex-direction: column;}
.nmMessageFree p{float:left;width:100%;text-align: center;font-size:22px;}
.nmMessageFree a{float:left;font-size:18px;background-color:var(--logocolor);padding:10px 30px;border-radius:30px;}
.nmSendMessage{float:left;width:100%;height:calc(25vh - 65px);position:relative;overflow:hidden;}
.nmEmojiArea{float:left;width:100%;margin:3px 0;display:none;position:absolute;z-index:9999;background-color: var(--background2);}
.nmEmojiArea a{float:left;width:auto;background-color:var(--background);border-radius:10px;}
.nmArea{float:left;width:100%;position:relative;height:auto;padding:0;}
.nmArea textarea{float:left;width:100%;height:calc(25vh - 65px);border:none;background-color:var(--background);color:var(--text);border-radius:10px;padding:25px 40px 20px 20px;}
.nmArea textarea:focus{border:1px solid var(--background);outline:0;}
.nmArea textarea:focus-visible{border:1px solid var(--background);outline: 0;}
.nmArea .send{position: absolute;right:15px;bottom:20px;}
.nmArea .send img{height: 30px;width: 30px;object-fit: cover;}
.nmArea .tip{position: absolute;left:20px;bottom:20px;}
.nmArea .tip img{height: 20px;width: 20px;object-fit: cover;}
.nmArea .ipost{position: absolute;left:50px;bottom:20px;}
.nmArea .ipost img{height: 20px;width: 20px;object-fit: cover;filter:invert(1);}
.nmArea .emoji{position: absolute;right:20px;top:35px;}
.nmArea .emoji img{height: 20px;width: 20px;object-fit: cover;}
.nmMessageLeft{float:left;width:100%;padding:10px 5% 10px 10px;position:relative;margin:10px 0 0;}
.nmMessageLeft img{float:left;width:35px;height:35px;object-fit: cover;margin:3px 10px 3px 10px;border-radius: 50%;}
.nmmLeftMsg{float:left;max-width:calc(100% - 70px);background-color:var(--background2);border-radius:20px;padding:6px 12px;position: relative;overflow: hidden;min-width: 60px;text-align: center;}
.nmMessageLeft p{float:left;width:100%;margin:0;}
.nmMessageLeft span{float:left;width:calc(100% - 70px);position:absolute;right:-60px;top:0;background-color:var(--logocolor);color:var(--text);height:100%;max-height: 50px;width:60px;overflow: hidden;border-radius:20px;font-size:14px;line-height:16px;display:flex;align-items: center;justify-content: center;font-weight: 500;text-align: center;padding:0 5px;transition:1s;}
.nmmRightArea{float:right;width:100%;padding:10px 10px 10px 15%;position:relative;margin:10px 0 0;}
.nmmraArea{float:right;width:250px;position: relative;}
.nmmraImage{float:right;width:100%;height:200px;object-fit: cover;}
.nmmraImage img{float:left;width:100%;}
.nmmraBtn{float:left;width:130px;background-color: var(--logocolor);color:var(--text);position: absolute;left:60px;top:155px;padding:3px 0;text-align: center;}
.nmmraCover{float:left;width:100%;height:200px;background-color: var(--black30);position: absolute;left:0;top:0;}
.nmmLeftArea{float:right;width:100%;padding:10px 15% 10px 10px;position:relative;margin:10px 0 0;}
.nmmlaArea{float:left;width:250px;position: relative;}
.nmmlaImage{float:left;width:100%;height:200px;object-fit: cover;}
.nmmlaImage img{float:left;width:100%;}
.nmmlaBtn{float:left;width:130px;background-color: var(--logocolor);color:var(--text);position: absolute;left:60px;top:155px;padding:3px 0;text-align: center;border-radius:5px;}
.nmmlaCover{float:left;width:100%;height:200px;background-color: var(--black30);position: absolute;left:0;top:0;}
.nmMessageRight{float:right;width:100%;padding:10px 10px 10px 5%;position:relative;margin:10px 0 0;}
.nmMessageRight img{float:right;width:35px;height:35px;object-fit: cover;margin:3px 10px 3px 10px;border-radius: 50%;}
.nmmRightMsg{float:right;max-width:calc(100% - 70px);background-color:var(--background2);border-radius:20px;padding:6px 12px;position: relative;overflow: hidden;min-width: 60px;text-align: center;}
.nmMessageRight p{float:left;width:100%;margin:0;}
.nmMessageRight span{float:left;width:calc(100% - 70px);position:absolute;left:-60px;top:0;background-color:var(--logocolor);color:var(--text);height:100%;max-height: 50px;width:60px;overflow: hidden;border-radius:20px;font-size:14px;line-height:16px;display:flex;align-items: center;justify-content: center;font-weight: 500;text-align: center;padding:0 5px;transition:1s;}

.allTra{float:left;width:35%;border-left:1px solid var(--border);border-right:1px solid var(--border);height:100vh;overflow-y:scroll;overflow-x: hidden;}
.allTra::-webkit-scrollbar{display:none;}
.atHeader{float:left;width:100%;border-bottom:1px solid var(--border);padding:10px 10px 17px 15px;}
.atHeader h3{font-size:22px;margin:5px 0 0;}
.atBody{float:left;width:100%;padding:10p;}
.atBody a{float:left;width:100%;padding:10px 15px;background-color:var(--background);font-weight: 500;padding:15px;transition:0.6s;}
.atBody img{float:right;width:17px;height:17px;object-fit: cover;filter:invert(1);transform: rotate(180deg);margin:3px 0;}
.atBody a:hover{background-color:var(--background2);}
.atBodyActive{background-color: var(--background2)!important;}

.selectTra{float:left;width:65%;border-right:1px solid var(--border);height: 100vh;}
#traPurc{display:none;}
.stHeader{float:left;width:100%;border-bottom:1px solid var(--border);padding:10px 10px 11px;height:62px;}
.stHeader h3{font-size:22px;margin:5px 0 0;}
.stFilter{float:left;width:100%;height:auto;border-bottom:1px solid var(--border);}
.stFilter a{float:left;padding:15px;font-weight: 600;font-size:18px;}
.stFilter a:hover{color:var(--logocolor)!important;border-bottom:2px solid var(--logocolor)!important;}
.stFilterActive{color:var(--logocolor)!important;border-bottom:2px solid var(--logocolor)!important;}
.stSelect{float:left;width:100%;padding:15px 10px;}
.stSelect a{float:left;padding:5px 12px;border-radius: 30px;background-color:var(--background3);margin:0 10px 0 0;transition:1s;}
.stSelect a:hover{background-color:var(--logocolor)!important;}
.stSelectActive{background-color:var(--logocolor)!important;}
.stBody{float:left;width:100%;display:none;height:79vh;overflow: scroll;}
.stBody::-webkit-scrollbar{display:none;}
#selectAll, #selectAlls{display: block;}
.stTraHead{float:left;width:100%;border-bottom:1px solid var(--border);padding:15px 10px;}
.stTraHead p{float:left;width:calc(100% - 80px);margin:0;font-weight: 500;}
.stTraHead span{float:right;width:80px;font-weight: 500;text-align: right;}
.stTra{float:left;width:100%;border-bottom:1px solid var(--background2);padding:15px 10px;}
.stTra p{float:left;width:calc(100% - 80px);margin:0;}
.stTra span{float:right;width:80px;text-align: right;}

.allCollections{float:left;width:35%;border-left:1px solid var(--border);border-right:1px solid var(--border);height:100vh;overflow-y:scroll;overflow-x: hidden;}
.allCollections::-webkit-scrollbar{display:none;}
.nowCollections{float:left;width:65%;border-right:1px solid var(--border);height: 100vh;}
.acHeader{float:left;width:100%;border-bottom:1px solid var(--border);padding:10px 10px 7px;}
.acHeader h3{font-size:22px;margin:5px 0 0;}
.acSelect{float:left;width:100%;border-bottom:1px solid var(--border);}
.acSelect a{float:left;width:auto;padding:20px;font-weight: 600;}
.acSelect a:hover{border-bottom:2px solid var(--logocolor);color:var(--logocolor)!important;}
.acSelectActive{border-bottom:2px solid var(--logocolor);color:var(--logocolor)!important;}
.acSelectFilter{float:right;width:35px;height:35px;margin:15px 15px 15px 0;}
.acSelectFilter img{float:left;width: 100%;height: 100%;object-fit: contain;filter:invert(1);}
.raFilters{position:fixed;right:-300px;width:280px;height: 100vh;background-color: var(--background1);z-index:99999;padding:30px;box-shadow: 0 0 10px 5px var(--background3);transition: 0.6s;}
.raFilter{float:left;width:100%;margin:10px 0;}
.raFilter h6{float:left;width:100%;font-weight: 500;font-size:16px;}
.raFilter input{float:left;width:100%;font-size:14px;padding:3px 10px;line-height: 20px;border-radius:3px;border:1px solid var(--border);height:30px;}
.raFilter select{float:left;width:100%;font-size:14px;padding:3px 10px;line-height: 20px;border-radius:3px;border:1px solid var(--border);height:30px;}
.rafiDouble{float:left;width:40%!important;margin-right:5%!important;}
.rafClose{position: absolute;left:15px;top:-5px;font-size:24px;font-weight: 600;cursor: pointer;}
.rafBtn{float:left;width:100%;background-color: var(--logocolor);padding:5px 10px;text-align: center;margin:10px 0 0 0;border-radius: 5px;}



.acFilter{float:left;width:100%;padding:10px;margin-bottom:20px;}
.acFilter p{float:left;width:calc(100% - 30px);margin:0;line-height: 30px;font-size:16px;font-weight: 500;}
.acFilter img{float:left;Width:30px;height:30px;object-fit: cover;filter:invert(1)}
#lists{float:left;width:100%;}
#bookmarks{float:left;width:100%;display:none;}

.listsItem{float:left;width:100%;padding:10px;}
.listsItemActive{background-color: var(--logocolor20);}
.listsItem:hover{background-color:var(--background2);}
.listsItemData{float:left;width:calc(100% - 120px);}
.listsItemData p{float:left;width:100%;margin:-2px 0 3px 0;line-height: 20px;font-weight: 500;}
.listsItem a{float:left;width:100%;font-size: 14px;}
.listsItemPp{float:left;width:105px;position:relative;margin-right:15px;}
.listsItemPp img{float:right;position:relative;width:35px;height:35px;object-fit: cover;border-radius: 50%;margin-right:-15px;}

#subscriptions{float:left;width:100%;display:block;}
#subscriptions .acHeader{padding:10px 10px 17px 20px;}
#following{float:left;width:100%;display:none;}
#subCreators{float:left;width:100%;}
#subPosts{float:left;width:100%;display:none;}
.ncItems{float:left;width:100%;}
.scUsers{float:left;width:50%;padding:1.5%;}
.scUser{float:left;width:100%;background:linear-gradient(27deg, var(--black2) 30%, var(--black1) 60%, var(--background) 100%);border-radius:10px;overflow: hidden;}
.scUserHeader{float:left;width:100%;height:120px;position:relative;}
.scuhBackground{float:left;position: absolute;width:100%;height:120px;object-fit: cover;z-index:998;}
.scuhCover{float:left;position:absolute;width:100%;background-color: var(--coverblack);height:120px;z-index:999;}
.scuhDots{position:absolute;right:10px;top:10px;width:25px;z-index:9999;filter:invert(1);}
.scUserData{float:left;width:100%;position:relative;top:-25px;z-index:9999;padding:0 10px}
.scUserData img{float:left;width:80px;height:80px;object-fit: cover;border-radius: 50%;margin-right:10px;}
.scUserData p{float:left;width:calc(100% - 90px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;margin:0;margin-top:30px;}
.scUserData a{float:left;width:calc(100% - 90px);overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.scUserFunctions{float:left;width:100%;margin:-5px 0 10px;padding:0 10px;}
.scUserFunctions a{float:left;width:auto;padding:8px 18px 7px 8px;background-color: var(--background3);border-radius:30px;min-width:100px;display:flex;justify-content: center;font-weight: 600;}
.scUserFunctions a:last-child{float:right;}
.scUserFunctions a img{float:left;width:30px;height:30px;object-fit: cover;padding:5px;margin-right: 5px;}
.scUserButtons .upUserF{padding:8px 5px;}
.scUserButtons .upUserF a{padding:6px 0;}
.scUserButtons .upUserS{margin:10px 5px;width:calc(100% - 10px);}
.scUserButtons .upUserS a{padding:8px;font-size:14px;}
.scUser span{float:left;width:100%;color:var(--shadow);font-size:14px;margin:10px 10px 15px}

.editProfile{float:lefT;width:100%;}
.editProfile a{float:left;width:100%;padding:10px 15px;background-color:var(--background);font-weight: 500;padding:15px;transition:0.6s;}
.editProfile a:hover{background-color: var(--logocolor40);}
.editProfile img{float:right;width:15px;height:15px;object-fit: cover;filter:invert(0.6);transform: rotate(180deg);margin:4px 0;}
.editProfileActive{background-color:var(--background2)!important;}
.profileDetail{float:left;width:100%;position:relative;}
.pdHeader{float:left;width:100%;height:52px;padding:6px;border-bottom:1px solid var(--background3);}
.pdHeader h3{float:left;font-size:22px;margin:5px 0 0;}
.pdDetails{float:left;Width:100%;}
.pdBackground{float:left;width:100%;height:200px;background:linear-gradient(60deg, var(--background1) 0%, var(--background) 70%, var(--background2) 130%);display:flex;align-items: center;justify-content: center;position:relative;}
.pdBackgroundImg{float:left;position: absolute;left:0;top:0;width: 100%;height: 100%;object-fit: cover;z-index:997;}
.pdBackgroundCover{float:left;position:absolute;width:100%;height:100%;background-color: var(--coverblack);z-index:998;left:0;top:0;}
.pdBackground a{position:relative;z-index:999;}
.pdBackground a img{float:left;width:22px;height:17px;object-fit: contain;filter:invert(1);margin:2px 5px 2px 0;}
.pdPP{float:left;width:100px;height:100px;border:5px solid var(--background0);border-radius:50%;padding:30px;position:absolute;top:210px;left:20px;z-index:999;background:linear-gradient(50deg, var(--background1) 10%, var(--background1) 80%, var(--background2) 130%);}
.pdPPCover{float:left;position:absolute;width:100px;height:100px;background-color: var(--coverblack);z-index:998;left:-3px;top:-3px;border-radius: 50%;}
.pdPP a{position: relative;z-index: 999;}
.pdPP img{float:left;width:100%;height: 100%;object-fit: contain;filter:invert(1)}
.pdPPIMG{float:left;position:absolute!important;left:-3px;top:-3px;width:96px!important;height: 96px!important;object-fit: cover!important;border-radius: 50%;filter:invert(0)!important;}
.pdDatas{float:left;width:100%;margin-top:100px;padding:10px 20px;}
.pdData{float:left;width:100%;position:relative;margin:20px 0;}
.pdData input{float:left;width:100%;background-color:var(--background0);border:1px solid var(--background2);border-radius:5px;padding:8px 10px 5px 10px;color:var(--text);font-size:14px;line-height: 20px;}
.pdData textarea{float:left;width:100%;background-color:var(--background0);border:1px solid var(--background2);border-radius:5px;padding:8px 10px 5px 10px;color:var(--text);font-size:14px;line-height: 20px;}
.pdData label{float:left;position: absolute;left:10px;top:-8px;font-size:13px;z-index:9999;background-color:var(--background);line-height: 13px;padding:0 1px;}
.pdSave{float:right;width:auto;padding:5px;width:130px;background-color: var(--logocolor);margin-right:20px;border-radius:20px;text-align: center;font-size:14px;}


#account{display:none;}
#kinkys{display:none;}
#notifications{display:none;}

.pdAccount{float:left;width:100%;}
.pdAccount h5{float:left;width:100%;padding:12px 20px;font-size:16px;font-weight: 500;border-bottom:1px solid var(--background2);border-top:1px solid var(--background2);margin:0;}
.pdAccount h5:first-child{border-top:transparent;}
.pdAccount p{float:left;width:100%;padding:10px 20px;font-size:16px;font-weight: 500;cursor:pointer;transition: 0.6s;margin:0;}
.pdAccount p:hover{background-color: var(--logocolor40);}
.pdAccount p img{float:right;width:10px;height:10px;object-fit: cover;filter:invert(0.6);transform: rotate(180deg);margin:4px 0;}
.pdaDatas{float:left;width:100%;padding:0 20px;margin:20px 0 10px;display:none;}
.pdaData{float:left;width:100%;position:relative;margin:0 0 10px 0;}
.pdaData input{float:left;width:100%;background-color:var(--background0);border:1px solid var(--background2);border-radius:5px;padding:5px 10px;color:var(--text);}
.pdaData textarea{float:left;width:100%;background-color:var(--background0);border:1px solid var(--background2);border-radius:5px;padding:5px 10px;color:var(--text);}
.pdaData label{float:left;position: absolute;left:10px;top:-8px;font-size:13px;z-index:9999;background-color:var(--background);line-height: 13px;padding:0 1px;}
.pdaDatas a{float:right;width:auto;padding:5px;width:300px;background-color: var(--logocolor);border-radius:20px;text-align: center;font-size:14px;margin:10px calc(50% - 150px);}

.pdKinks{float:left;width:100%;padding:10px;}
.pdKinks h5{float:left;width:100%;padding:10px 5px;border-bottom:1px solid var(--background2);}
.pdKinks a{float:left;padding:5px 10px;margin:5px 3px;background-color: var(--background3);border-radius:50px;line-height: 15px;font-size: 15px;transition: 0.8s;}
.pdKinks a:hover{background-color:var(--logocolor)!important;}
.pdKinksActive{background-color: var(--logocolor)!important;}
.pdNotifications{float:left;width:100%;margin:3% 0;}
.pdNotification{float:left;width:100%;padding:10px 20px;border-bottom:1px solid var(--background2);}
.pdNotification span{float:left;width:calc(100% - 100px);}
.pdNotification input{float:right;}
input.kink-switch {position: relative;appearance: none; outline: none;width: 60px; height: 30px;background-color: #ffffff; border: 1px solid #D9DADC;border-radius: 50px; box-shadow: inset -20px 0 0 0 #ffffff;transition-duration: 200ms;}
input.kink-switch:after {content: "";position: absolute;top: 1px; left: 1px;width: 26px; height: 26px;background-color: transparent;border-radius: 50%; box-shadow: 2px 4px 6px rgba(0,0,0,0.2);}
input.kink-switch:checked {border-color: #4ED164;box-shadow: inset 30px 0 0 0 #4ED164;}
input.kink-switch:checked:after {left: 30px;box-shadow: -2px 4px 3px rgba(0,0,0,0.05);}

.leftDetailMenu{float:left;width:300px;height:100vh;position:fixed;left:-300px;top:0;background-color:var(--background1);z-index:9999;transition:0.6s;}
.ldmClose{position:absolute;right:20px;top:10px;color:var(--text);font-size:20px;font-weight: 700;cursor: pointer;z-index:9999;}
.windowBlur{float:left;display:none;width:100%;height:100vh;position: fixed;left:0;top:0;z-index:9998;background-color:var(--coverblack);backdrop-filter: blur(8px);}
.ldUser{float:left;width:100%;margin:10px 0 0;}
.ldUserInfo{float:left;width:100%;margin:10px 0 7px;padding:10px 10px;border-radius:10px;transition:1s;}
.ldUserInfo img{float:left;width:45px;height:45px;object-fit:cover;border-radius:50%;border:1px solid #454545;background-color:var(--background);padding:5px;transition:1s;}
.ldUserInfo img:hover{box-shadow:0 0 10px 5px #454545;}
.ldUserInfo p, .laUser a{max-width: 150px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.ldUserInfo p{float:left;width:calc(100% - 80px);margin:1px 0 1px 10px;font-size:16px;line-height: 20px;font-weight: 600;}
.ldUserInfo a{float:left;width:calc(100% - 80px);margin:0 0 0 10px;font-size:14px;color:var(--textblur)!important;}
.ldUserData{float:left;width:44%;color:var(--text);transition:1.2s;margin:0 3% 0 3%;padding:5px 0;}
.ldUserData:hover{background-color:var(--logocolor);border-radius:10px;}
.ldUserData h6{float:left;width:100%;text-align: center;font-weight: 600;font-size:16px;margin:0;}
.ldUserData p{float:left;Width:100%;text-align:center;color:var(--textblur);margin:5px 0 0 0;font-size:14px;}
.ldMenuGroup{float:left;width:100%;padding:5px 0;border-bottom:1px solid var(--background2);}
.ldMenu{float:left;width:100%;}
.ldMenu li{float:left;width:100%;margin:1px 0;padding:2px 10px;border-radius:30px;transition:0.6s;}
.ldMenu li img{float:left;width:30px;margin:3px 8px 0 0;filter:invert(1);padding:5px;border-radius:5px;transition:1.2s;}
.ldMenu li p{float:left;width:calc(100% - 50px);font-weight: 400;font-size:15px;line-height: 35px;margin:0;}
.ldMenu li:hover{background-color:var(--logocolor40);}
.ldMenu li:hover img{background-color:var(--logocolorinvert);border-radius:18px!important;}

.containers{float:left;width:1100px!important;margin:0 calc(50% - 550px);}
.spaceBetween{display:flex;align-items: center;justify-content: space-between;}
.storePage{float:left;position:fixed;left:0;top:0;width:100%;height:100vh;background-color: white;margin:0!important;padding:0!important;overflow-y: scroll;}
.storeHeader{float:left;width:100%;}
.storeHeaderBand{float:left;width:100%;padding:8px;text-align: center;background-color:var(--logocolor);}
.storeHeaderBand a{color:white!important;}
.storeHeaderTopMenu{float:left;width:100%;margin:10px 0;}
.shmLogo{float:left;width:80px;height:65px;}
.shmLogo img{float:left;width:100%;height:100%;object-fit: contain;}
.shmSearch{float:left;width:300px;position: relative;}
.shmSearch input{float:left;width:100%;height:35px;border:1px solid var(--shadow);border-radius: 5px;padding:5px 30px 5px 10px;}
.shmSearch img{float:right;position: absolute;right:10px;top:10px;width:16px;}
.shmFunction{float:right;width:100px;}
.shmFunction a{float:left;width:20px;margin:5px 10px;position: relative;}
.shmFunction img{float:left;width:100%;}
.shmFunction a span{position:absolute;right:-5px;top:-5px;background-color: var(--logocolor);color:white;padding:2px 5px;border-radius: 50%;font-size: 12px;line-height: 12px;}
.storeHeaderMenu{float:left;width:100%;background-color: var(--logocolor20);padding:5px;}
.shmCenter{display:flex;align-items: center;justify-content: center;}
.shmCenter a{float:left;padding:5px 15px;color:#000!important;position:relative;}
.shmSubMenu{float:left;min-width:180px;z-index:9999;position: absolute;left:0;top:0;background-color:white;top:30px;left:-20px;padding:10px 20px;box-shadow: 0 0 5px 2px var(--shadow);display:none;}
.shmSubMenu a{float:left;width:100%;padding:7px 0;font-size:15px;}
.shmSubMenu a img{float:left;height:27px;width:27px;object-fit: contain;margin-right:10px;}
.storeBanner{float:left;width:100%;height:60vh;}
.storeBanner img{float:left;width: 100%;height: 100%;object-fit: cover;}
.storeBody{float:left;width:100%;margin:2% 0;}
.storeBody p{float:left;width:100%;color: #000!important;}
.sbExploreArea{float:left;width:100%;margin:3% 0;}
.sbExploreArea h5{float:left;width:calc(97% - 150px);font-size:20px;font-weight: 600;color:#000;margin-left:1.5%;}
.sbExploreArea a{float:right;width: 150px;background-color: var(--logocolor);border-radius: 3px;padding:7px 5px;text-align: center;margin-right:1.5%;}

.sbeaItem{float:left;width:15%;margin:2% 2% 2% 0;}
.sbeaItem:last-child{margin-right:0;}
.sbeaItem img{float:left;width:150px;height: 150px;object-fit: cover;background-color: var(--logocolor20);border-radius: 50%;margin:2% calc(50% - 75px);}
.sbeaItem p{float:left;width:100%;text-align: center;font-weight: 500;margin:10px 0 0;}
.sbeaItem2{float:left;width:15.6%;margin:2% 0.5% 2% 0.5%;}
.sbeaItem2:last-child{margin-right:0;}
.sbeaItem2 img{float:left;width:150px;height: 150px;object-fit: cover;background-color: var(--logocolor20);border-radius: 5px;margin:2% calc(50% - 75px);}
.sbeaItem2 p{float:left;width:100%;font-weight: 500;margin:10px 0 0;}
.priceNew{float:left;color:var(--background1)!important;font-weight: 600;font-size: 13px;}
.priceOld{float:left;color:var(--background3)!important;font-weight: 400;font-size: 13px;text-decoration: line-through;margin-left:5px;}
.sbBannerArea{float:left;width:100%;height:250px;}
.sbBannerArea img{float:left;width:100%;height:100%;object-fit: cover;border-radius:5px;}

.sbFooter{float:left;width:100%;padding:3% 0 0;background-color:var(--logocolor20);}
.sbFooterSocial{float:lefT;width:31%;}
.sbFooterSocial h5{float:left;width:100%;color:var(--logocolor);font-weight: 600;margin-bottom:30px;}
.sbFooterSocial a{float:left;background-color:var(--logocolor);border-radius:50%;width:22px;height:22px;padding:5px;margin:5px 7px 5px 0;}
.sbFooterSocial a img{float:left;width: 100%;height:100%;object-fit: cover;filter:invert(1);}
.sbFooterItem{float:lefT;width:22%;margin-right:1%;}
.sbFooterItem h5{float:left;width:100%;color:var(--logocolor);font-weight: 600;margin-bottom:30px;}
.sbFooterItem a{float:left;width:100%;font-size:14px;font-weight: 400;color:var(--background3)!important;}
.sbFooterCopy{float:left;width:100%;text-align: center;color:var(--background3);margin:50px 0 0;font-weight: 500;font-size: 14px;}

.breadcrump{float:left;width:100%;margin:10px 0 15px;}
.breadcrump a{float:left;color:#222!important;margin:0 5px 0 0;font-weight: 400;}
.breadcrump a:last-child{font-weight: 500!important;}
.categoriesLeft{float:left;width:230px;}
.categoriesLeft h3{float:left;width:100%;color:#222;font-size:18px;margin:10px 0 5px;}
.clCheckBox{float:left;width:100%;margin:2px 0;}
.clCheckBox input{float:left;width:13px;height: 13px;margin:6px 10px 0 0;}
.clCheckBox span{float:left;width:calc(100% - 30px);color:#222;font-weight: 500;font-size:14px;}
.clPriceBox{float:left;width:85%;margin:0 15% 10px 0;}
.clPriceBox input{float:left;width:calc(50% - 25px);font-size: 14px;line-height: 20px;height: 35px;padding:3px 5px;}
.clPriceBox span{float:left;width:20px;margin:3px 15px;color:#222;}

.categoriesRight{float:left;width:calc(97% - 230px);margin-left:3%;}
.categoriesRight h1{float:left;width:100%;color:#222;font-size:24px;padding:5px 10px;}
.categoriesRight p{float:left;width:100%;color:#222;font-size:14px;padding:5px 10px;}
.crBanner{float:left;width:100%;height:200px;object-fit:cover;border-radius: 15px;padding:5px 10px;} 
.crItems{float:left;width:25%;padding:10px 10px 20px;box-sizing: border-box;}
.crItem{float:left;width:100%;}
.crItem:last-child{margin-right:0;}
.crItem img{float:left;width:100%;height: 200px;object-fit: cover;background-color: var(--logocolor20);border-radius: 5px;}
.crItem p{float:left;width:100%;font-weight: 500;margin:10px 0 0;}

.itemDetails{float:left;width:100%;margin:10px 0;}
.itemDetails h1{float:left;width:100%;font-size: 24px;color:#222;}
.idLeftArea{float:left;width:55%;margin:20px 5% 0 0;}
.idlaImages{float:left;width:100%;}
.idlaImagesGroup{float:left;width:100px;}
.idlaSelected{border:1px solid var(--logocolor);}
.idlaImagesGroup img{float:left;width:90px;height: 90px;margin:2px 10px 5px 0;object-fit: cover;border:1px solid var(--border);border-radius: 2px;cursor: pointer;}
.idlaMainImage{float:left;width:calc(100% - 100px);}
.idlaMainImage img{float:left;width:100%;height:500px;object-fit: cover;}
.idRightArea{float:left;width:40%;margin:20px 0 0;}
.idRightArea p{float:left;width:100%;font-size:14px;color:#222;}
.idraSection{float:left;width:100%;}
.idraSection label{float:left;width:100%;color:#222;font-size:14px;font-weight: 600;}
.idraSection select{float:left;width:100%;color:#222;margin:10px 0;height: 35px;}
.idRightArea h5{float:left;width:100%;font-size:14px;color:#222;font-weight: 600;margin:10px 0 5px;}
.idraFunction{float:left;width:100%;margin:10px 0;}
.idraFunction input{float:left;width:50px;height:30px;border:1px solid var(--border);text-align: center;}
.idraFunction a{float:left;width:calc(100% - 60px);margin-left:10px;background-color: var(--logocolor);padding:3px 10px;text-align: center;border-radius: 3px;}
.morePayment{float:left;width:100%;background-color: var(--background);padding:5px 10px;text-align: center;border-radius: 3px;}
.idlaDesc{float:left;width:100%;color:#222;margin:50px 0 20px 0;}
.idFullArea{float:left;width:100%;color:#222;}
.idItems{float:left;width:20%;padding:10px 10px 20px;box-sizing: border-box;}
.idItem{float:left;width:100%;}
.idItem:last-child{margin-right:0;}
.idItem img{float:left;width:100%;height: 200px;object-fit: cover;background-color: var(--logocolor20);border-radius: 5px;}
.idItem p{float:left;width:100%;font-weight: 500;margin:10px 0 0;}

.shoppingCart{float:left;width:100%;margin:10px 0;min-height: 60vh;}
.scLeft{float:left;width:calc(100% - 300px);margin-right: 30px;color:#222;}
.scLeft h1{float:left;width:100%;margin:10px 0;font-size:24px;}
.scLeft table{float:left;width:100%;}
.scLeft table td,.scLeft table th {border: 1px solid var(--logocolor20);text-align: left;padding: 8px;}
.scLeft table tr:nth-child(even) {background-color: var(--logocolor20);}
.scLeft table img{float:left;width:50px;}

.scRight{float:left;width:270px;}
.scrCoupon{float:left;width:100%;padding:10px 0;border-bottom:1px solid var(--border);}
.scrCoupon p{float:left;width:100%;margin:5px 0;color:#222;font-weight: 600;font-size:14px;}
.scrCoupon input{float:left;width:calc(100% - 110px);margin-right: 10px;padding:3px 10px;font-size:14px;border-radius: 5px;}
.scrCoupon a{float:left;width:100px;text-align: center;padding:5px 10px;background-color: var(--logocolor);font-size:14px;}

.scrTotals{float:left;width:100%;}
.scrTotal{float:left;width:100%;margin:5px 0;}
.scrTotal:last-child{border-bottom:1px solid var(--border);padding-bottom:10px;}
.scrTotal span{float:left;width:calc(100% - 60px);color:#222;}
.scrTotal p{float:left;width:50px;margin:0 0 0 10px;font-weight: 500;color:#222;text-align: right;}
.scrCheckout{float:left;width:100%;padding:10px;text-align: center;background-color:var(--logocolor);margin:20px 0;}

.scForm{float:left;width:100%;}
.scFormItem{float:left;width:48%;margin:1%;}
.scFormItem label{float:left;width:100%;font-weight: 600;}
.scFormItem input{float:left;width:80%;margin-right:20%;border-radius: 5px;border:1px solid var(--background3);box-shadow: 0 0 5px 3px var(--border);}
.scFormItem2{float:left;width:96%;margin:1%;}
.scFormItem2 label{float:left;width:100%;font-weight: 600;}
.scFormItem2 input{float:left;width:92%;margin-right:8%;border-radius: 5px;border:1px solid var(--background3);box-shadow: 0 0 5px 3px var(--border);}

.paymentType{float:left;width:100%;padding:10px 0 20px;border-bottom:1px solid var(--border);}
.paymentType p{float:left;width:100%;font-weight: 500;color:#222;}
.paymentType select{float:left;Width:100%;border-radius:5px;padding:2px 5px;}

.applyArea{float:left;width:100%;}
.applyDetails{float:left;width:100%;margin:10px 0 5px;display:none;}
.applyDetails h3{float:left;width:100%;margin:5px 0 10px 0;font-size:20px;}
.adaBack{float:right;background-color: red;color:var(--text);padding:5px 15px;border-radius: 5px;margin:20px 10px 0 0;}
.adaSend{float:right;background-color: var(--logocolor);color:var(--text);padding:5px 15px;border-radius: 5px;margin:20px 20px 0 0;}
.adArea{float:left;width:23%;margin:10px 2% 10px 0;position: relative;}
.adAreaSwitch{float:left;width:22%;margin:10px 2% 10px 0;position: relative;}
.adAreafw{float:left;width:98%;margin:10px 2% 10px 0;position: relative;}
.adArea label, .adAreafw label{float:left;margin:5px 0;font-size:13px;font-weight: 500;position:absolute;left:10px; top:-16px;background-color:var(--background)}
.adAreaSwitch label{float:left;margin:5px 0;font-size:14px;font-weight: 500;position:absolute;left:70px; top:-2px;background-color:var(--background)}
.adArea p{float:left;margin:5px 0;font-size:13px;font-weight: 500;position:absolute;right:10px; top:-16px;background-color:var(--background)}
.adaFwInput{float:left;width:100%;border:1px solid var(--border);background-color:var(--background);border-radius: 5px;color:var(--text);padding:5px 10px;font-size:14px;}
.adaRangeInput{float:left;width:100%;border:1px solid var(--border);background-color:var(--background);border-radius: 5px;color:var(--text);padding:5px 10px;font-size:14px;height:40px;}
.adaSelect{float:left;width:100%;border:1px solid var(--border);background-color:var(--background);border-radius: 5px;color:var(--text);padding:5px 10px;font-size:14px;}
.adAreaSwitch{margin-left:10px!important;}
.bioArea{float:left;width:100%;position:relative;height:auto;padding:0;margin-top:15px;}
.bioArea textarea{float:left;width:100%;height:calc(25vh - 65px);border:none;background-color:var(--background);color:var(--text);border-radius:10px;padding:10px 40px 20px 15px;border:1px solid var(--border)}
.bioArea textarea:focus{border:1px solid var(--background);outline:0;}
.bioArea textarea:focus-visible{border:1px solid var(--background);outline: 0;}
.bioArea .send{position: absolute;right:15px;bottom:20px;}
.bioArea .send img{height: 30px;width: 30px;object-fit: cover;}
.bioArea .tip{position: absolute;left:20px;bottom:20px;}
.bioArea .tip img{height: 20px;width: 20px;object-fit: cover;}
.bioArea .emoji{position: absolute;right:15px;top:10px;}
.bioArea .emoji img{height: 20px;width: 20px;object-fit: cover;}
.bioEmojiArea{float:left;width:100%;margin:0;display:none;position:absolute;z-index:9999;background-color: var(--background2);top:-10px;}
.bioEmojiArea a{float:left;width:auto;background-color:var(--background);border-radius:10px;}
.adaFile{float:left;width:100%;background-color: var(--background);color:white;}

.myPosts{float:left;width:50%;height:150px;padding:2%;position:relative;background-color:var(--background);z-index:9999;}
.myPosts video{float:left;width:92%;height:94%;object-fit: cover;position: absolute;left:4%;top:3%;border-radius: 10px;}
.mypImage{float:left;width:92%;height:94%;object-fit: cover;position: absolute;left:4%;top:3%;border-radius: 10px;}
.mypCover{float:left;width:92%;height:94%;background-color: var(--black30);position: absolute;left:4%;top:3%;border-radius: 10px;z-index:998;}
.mypTitle{float:left;width:94%;z-index:999;position: relative;top:105px;left:3%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;font-size:14px;}
.mypFunc{position: absolute;right:7px;top:3%;height:94%;background: var(--background3);padding:10px 12px;display:flex;align-items: center;justify-content: center;transition: 1.5s;cursor:pointer;z-index:999;flex-direction: column;opacity: 0;border-radius: 0 10px 10px 0;}
.mypFunc img{float:left;width:30px;height:30px;object-fit: cover;margin:10px 0;}
.mypInputAreas{float:left;width:100%;padding:10px 20px;}
.mypInputArea{float:left;width:100%;margin:10px 0;position: relative;min-height:40px;}
.mypHalf{float:left;width:46%;margin-right:4%;}
.mypInputArea label{float:left;position: absolute;left:10px;top:-10px;background-color:var(--background);padding:0;margin:0;font-size: 16px;line-height: 16px;}
.mypInputArea input{float:left;width:100%;padding:5px 10px;background-color: var(--black1);border: 1px solid var(--background0);border-radius: 5px;color:var(--text);}
.mypInputArea select{float:left;width:100%;padding:5px 10px;background-color: var(--black1);border: 1px solid var(--background0);border-radius: 5px;color:var(--text);}
.mypdArea{float:left;width:100%;position:relative;height:auto;padding:0;margin-top:15px;}
.mypdArea textarea{float:left;width:100%;height:calc(25vh - 65px);border:none;background-color:var(--background);color:var(--text);border-radius:10px;padding:10px 40px 20px 15px;border:1px solid var(--border)}
.mypdArea textarea:focus{border:1px solid var(--background);outline:0;}
.mypdArea textarea:focus-visible{border:1px solid var(--background);outline: 0;}
.mypdArea .send{position: absolute;right:15px;bottom:20px;}
.mypdArea .send img{height: 30px;width: 30px;object-fit: cover;}
.mypdArea .tip{position: absolute;left:20px;bottom:20px;}
.mypdArea .tip img{height: 20px;width: 20px;object-fit: cover;}
.mypdArea .emoji{position: absolute;right:15px;top:30px;}
.mypdArea .emoji img{height: 20px;width: 20px;object-fit: cover;}
.mypdEmojiArea{float:left;width:100%;margin:-10px 0;display:none;position:absolute;z-index:9999;background-color: var(--background2);}
.mypdEmojiArea a{float:left;width:auto;background-color:var(--background);border-radius:10px;}
.mypiaTab{float:left;width:100%;}
.mypiaTab a{float:left;width:50%;background-color: var(--background2);color:white;text-align: center;padding:5px 10px;transition: 0.6s;border-radius:15px 0 0 15px;}
.mypiaTab a:last-child{border-left:1px solid var(--border);border-radius:0 15px 15px 0!important;}
.mypiaTab a:hover{background-color: var(--background0);}
.mypiaTabActive{background-color: var(--logocolor)!important;}
.mypiaTabActive:hover{background-color: var(--logocolor85)!important;}
#imagePostArea{float:left;width:100%;padding:15px 5px;}
#videoPostArea{display:none;float:left;width:100%;padding:15px 5px;}
#videoPostArea video{float:left;width: 100%;margin:20px 0;}
#videoPostArea p{float:left;margin:5px 0 5px 10px;}
#imagePostArea a, #videoPostArea a{float:left;padding:6px 15px;background-color: var(--logocolor);color:white;font-size:14px;border-radius:5px;}
.ipaImages{float:left;width:100%;margin: 10px 0;}
.ipaImage{float:left;width:100px;margin:5px 1% 5px 0;}
.ipaImage img{float:left;width:100%;height:100px;object-fit: cover;margin:5px 10px 5px 0;}
.ipaImage p{float:left;width:60px;margin:10px calc(50% - 30px);background-color: var(--background);color:var(--text);border:1px solid var(--background2);text-align: center;border-radius: 5px;}
.ipaImage input{float:left;width:60px;margin:10px calc(50% - 30px);background-color: var(--background);color:var(--text);border:1px solid var(--background2);text-align: center;border-radius: 5px;}
.setMain{float:left;width:80%;margin:3px 10%;padding:2px 3px!important;background-color: var(--background);color:var(--text);border:1px solid var(--background2);text-align: center;border-radius: 5px;}
.setMainDelete{float:left;width:80%;margin:3px 10%;padding:2px 3px!important;background-color: red!important;color:var(--text);border:1px solid var(--background2);text-align: center;border-radius: 5px;}

.postUpdateArea{float:left;width:100%;}
.postUpdateArea p{float:right;width:auto;margin:5px 0;padding:5px 10px;display: none;}
.postUpdateArea a{float:right;background-color: var(--logocolor);color:var(--text);text-align: center;margin:5px;padding:5px 15px;border-radius: 5px;}
#otcDESC{display: none;}

.myWallet{float:left;width: 100%;padding:10px 20px;}
.myWalletCredit{float:left;width:100%;}
.myWalletCredit p{float:left;width:100%;margin:5px 0 10px;}
.myWalletCredit span{color:var(--logocolor);font-weight: 600;font-size:20px;margin-left:5px;}
.myWallet table{float:left;width:100%;}
.myWallet table td,.myWallet table th {border: 1px solid var(--logocolor20);text-align: left;padding: 8px;}
.myWallet table tr:nth-child(even) {background-color: var(--logocolor20);}

.ccbillButton{float:left;width:150px;background-color: var(--logocolor);color:var(--text);margin:20px calc(50% - 75px);padding:5px 0;text-align: center;border-radius: 10px;}